import '../styles/index.scss';
import * as $ from 'jquery';
import smoothscroll from 'smoothscroll-polyfill';

(function() {
    'use strict';

    window.APP = {
        menu: [],
        menuPosition: 0,
    };

    smoothscroll.polyfill();

    const viewport = document.querySelector('.switch-scroll');
    const categoriesMenu = document.querySelector('.switch');
    const toggleScrollMenu = () => {
        const menuEl = document.getElementById('scrollMenu');
        const menuListEl = document.getElementById('menu');
        menuEl.classList.toggle('show', document.documentElement.scrollTop > menuListEl.offsetTop - 20);
    };

    const setActive = (index) => {
        const listScroll = document.querySelectorAll('.switch-scroll__button');
        const list = document.querySelectorAll('.switch__button');

        for (const el of [...list, ...listScroll]) {
            el.classList.remove('active');
        }

        list[index].classList.add('active');
        listScroll[index].classList.add('active');
    };

    const scrollMenuTo = (position) => {
        const viewportWidth = viewport.clientWidth;
        const scrollEl = document.getElementById('categoriesScrolling');
        const scrollWidth = scrollEl.clientWidth;
        let newPosition = position;

        if (viewportWidth + newPosition > scrollWidth) {
            newPosition = scrollWidth - viewportWidth;
        }

        if (viewportWidth > scrollWidth) {
            newPosition = 0;
        }

        APP.menuPosition = newPosition;
        viewport.scrollLeft = newPosition;
        categoriesMenu.scrollLeft = newPosition - 15;
    };

    const initScrollMenu = () => {
        const ST = document.documentElement.scrollTop;
        const buttons = document.querySelectorAll('.switch-scroll__button');
        const list = document.querySelectorAll('.category');

        for (const [index, el] of list.entries()) {
            if (ST < el.offsetTop && !!index) {
                setActive(index - 1);
                scrollMenuTo(buttons[index - 1].offsetLeft);
                break;
            }
        }
    };

    const handlerScroll = () => {
        const ST = document.documentElement.scrollTop;
        const buttons = document.querySelectorAll('.switch-scroll__button');
        const list = document.querySelectorAll('.category');

        for (const [index, el] of list.entries()) {
            if (ST > el.offsetTop - 60 && ST < el.offsetTop + 60) {
                setActive(index);
                scrollMenuTo(buttons[index].offsetLeft);
            }
        }

        toggleScrollMenu();
    };

    const handlerMenuScroll = () => {
        APP.menuPosition = viewport.scrollLeft;
    };

    const handlerLeftButton = () => {
        const position = APP.menuPosition - 100;

        if (position < 0) {
            scrollMenuTo(0);
            return;
        }

        scrollMenuTo(position - 100);
    };

    const handlerRightButton = () => {
        const position = APP.menuPosition + 100;
        const max = document.getElementById('categoriesScrolling').clientWidth - viewport.clientWidth;

        if (position > max) {
            scrollMenuTo(max);
            return;
        }

        scrollMenuTo(position + 100);
    };

    const setMenuPosition = (index) => {
        const viewportWidth = viewport.clientWidth;
        const list = document.querySelectorAll('.switch-scroll__button');

        let left = APP.menuPosition;
        const right = left + viewportWidth;

        const button = list[index];

        if (index && index < list.length - 1) {
            const buttonOffset = button.offsetLeft;
            const buttonWidth = button.clientWidth;
            const nextButton = list[Number(index) + 1];
            const prevButton = list[index - 1];

            if (viewportWidth + left - 150 < nextButton.offsetLeft) {
                left += 150;
            }

            if (buttonOffset + buttonWidth >= right) {
                left += buttonOffset + buttonWidth - right;
            }

            if (buttonOffset + buttonWidth + nextButton.clientWidth - left > right) {
                left += buttonOffset + buttonWidth + nextButton.clientWidth - left - right - 100;
            }

            if (prevButton && buttonOffset - prevButton < left) {
                left = buttonOffset - prevButton;
            }
        }

        scrollMenuTo(left);
    };

    const scrollTo = (name) => {
        document.querySelector(`[data-category="${name}"]`).scrollIntoView({
            behavior: 'smooth',
        });
    };

    const closeModal = () => {
        const modalEl = document.getElementById('modal');
        modalEl.classList.remove('show');
        modalEl.innerHTML = '';
    };

    const openModal = (e) => {
        let modalContent = '';
        const modalEl = document.getElementById('modal');
        const item = APP.menu.find(item => item.id === e.currentTarget.dataset.id);

        modalEl.classList.add('show');
        modalContent +=
            `
                <div class="modal__content">
                    <div class="modal__close js-close">×</div>
                    <div class="modal__image" style="background-image: url(${item.image});"></div> 
                    <div class="modal__information">
                        <div class="modal__title">${item.name}</div> 
                        <div class="modal__description">                    
                            <div class="modal__properties">
                                ${renderProperties(item.properties?.weight, item.properties?.volume, item.properties?.calories, 'modal')}
                            </div> 
                            <div class="modal__about">${item.description}</div>
                        </div>
                    </div>
                    <div class="modal__price">${item.price} <img class="modal__currency" src="public/rub.svg" alt="руб."></div>
                </div>
                <div class="modal__overlay js-close"></div>
            `;

        modalEl.innerHTML = modalContent;
        $('.js-close').on('click', closeModal);
    };

    const renderProperties = (weight, volume, calories, className) => {
        let result = '';

        if (weight) {
            result += `<span class="${className}__property">${weight} гр</span>`;
        }

        if (volume) {
            result += `<span class="${className}__property">${volume} мл</span>`;
        }

        if (calories) {
            result += `<span class="${className}__property">${calories} ккал</span>`;
        }

        return result;
    };

    const fetchAndFirstRenderMenu = async () => {
        const data = await $.ajax({
            url: '../data.json',
            method: 'GET',
            dataType: 'json',
        });

        if (data) {
            APP.menu = data.products;

            const menuEl = document.getElementById('menu');
            const categoriesEl = document.getElementById('categories');
            const categoriesScrollingEl = document.getElementById('categoriesScrolling');
            let menuList = '';
            let scrollingList = '';
            let categoriesList = '';

            for (const [index, category] of data.categories.entries()) {
                scrollingList += `<div class="switch-scroll__button js-click" data-index="${index}" data-switch="${category.name}">${category.name}</div>`;
                categoriesList += `<div class="switch__button js-click" data-index="${index}" data-switch="${category.name}">${category.name}</div>`;
                menuList += `<div class="category" data-category="${category.name}"><div class="category__title">${category.name}</div>`;

                for (const item of data.products.filter(i => category.productIds.includes(i.id))) {
                    menuList +=
                        `
                            <div class="item js-more" data-id="${item.id}">
                                <div class="item__body">
                                    <div class="item__image" style="background-image: url(${item.image});"></div> 
                                    <div class="item__information">
                                        <div class="item__title">${item.name}</div> 
                                        <div class="item__description">                    
                                            <div class="item__properties">
                                                ${renderProperties(item.properties?.weight, item.properties?.volume, item.properties?.calories, 'item')}
                                            </div> 
                                            <div class="item__about">${item.description}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item__price">${item.price} <img class="item__currency" src="public/rub.svg" alt="руб."></div>
                            </div>
                        `;
                }

                menuList += '</div>';
            }

            menuEl.innerHTML = menuList;
            categoriesEl.innerHTML = categoriesList;
            categoriesScrollingEl.innerHTML = scrollingList;

            $('.js-click').on('click', (e) => {
                setActive(e.currentTarget.dataset.index);
                setMenuPosition(e.currentTarget.dataset.index);
                scrollTo(e.target.dataset.switch);
                window.removeEventListener('scroll', handlerScroll);
                setTimeout(() => {
                    toggleScrollMenu();
                    handlerScroll();
                    window.addEventListener('scroll', handlerScroll);
                }, 1000);
            });
            $('.js-more').on('click', openModal);
            initScrollMenu();
        }
    };
    fetchAndFirstRenderMenu();
    $('.js-left-button').on('click', handlerLeftButton);
    $('.js-right-button').on('click', handlerRightButton);
    window.addEventListener('scroll', handlerScroll);
    viewport.addEventListener('scroll', handlerMenuScroll);
})();
